import React from 'react';
import styled from 'styled-components';


const PieChartAnim = styled.div`

    @property --p{
        syntax: '<number>';
        inherits: true;
        initial-value: 0;
    }

    --p: 22;
    --b: 22px;
    --c: #343d6f;
    --w: 150px;
    width: var(--w);
    aspect-ratio: 1 / 1;
    position: relative;
    display: inline-grid;
    margin: 5px;
    place-content: center;
    font-size: 25px;
    font-weight: bold;
    animation: 1s ease 0.5s 1 normal both running p;
    border: 1px solid #353d6f;
    border-radius: 90px;
    background: #6d90bd;
    font-weight: 100;
    color: white;
    font-size: 40px;

    
    &&::before {
        content:"";
        position:absolute;
        border-radius:50%;
    }
    &&::after {
        content:"";
        position:absolute;
        border-radius:50%;
        }
    &&::before {
        inset:0;
        background:
            radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
            conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
        -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
        mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    }
    &&::after {
        inset:calc(50% - var(--b)/2);
        background:var(--c);
        transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
    }
    
    &&::before {
        background-size:0 0,auto;
    }
    &&::after {
        content:none;
    }

`;


const Women = styled.div<{color: string, width: string, height: string, position: string, keyframe: string, bottom?: string, right?: string, delay?: string}>`
    ${({bottom}) => bottom ? `bottom: ${bottom}`:''};
    ${({right}) => right ? `right: ${right}`:''};
    position: ${({position}) => `${position}`};
    height: ${({height}) => `${height}`};
    width: ${({width}) => `${width}`};
    background: url(/woman_icon.svg) 0 0 10%;
    fill: ${({color}) => `${color}`};
    mask-image: url(/woman_icon.svg);
    -webkit-mask-image: url(/woman_icon.svg);
    background-color: ${({color}) => `${color}`};
    mask-size: 16px;
    -webkit-mask-size: 16px;
    transition: all 1s;
    animation: 1s ease 0.5s 1 normal both running ${({keyframe}) => `${keyframe}`};
    ${({delay}) => delay ? `animation-delay: ${delay}`:''};
    @keyframes ${({keyframe}) => `${keyframe}`} {
        from {
            width: 0;
            height: ${({height}) => `${height}`};
        }
        to {
            width: ${({width}) => `${width}`};
            height: ${({height}) => `${height}`};
        }
    }
`;

const MainInfo = styled.div`
    @media only screen and  (max-width : 600px) { 
        display: none;
    }
`;

const MainInfoDescription = styled.div`
    flex: 1;
    padding: 20px;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
    color: #353d6e;
    div {
        font-weight: 200;
        font-size: 16px;
    }
`;

const MapWorld = styled.div`
    top: -25px;
    left: -135px;
    transform: rotate(-3deg);
    position: absolute;
    width: 500px;
    height: 200px;
    -webkit-mask-image: url(/worldmap.svg);
    background-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
    background-color: #c8d4e5;
`;

const MapPoland = styled.div`
    top: -25px;
    right: -150px;
    transform: rotate(2deg);
    position: absolute;
    width: 500px;
    height: 200px;
    -webkit-mask-image: url(/polandmap.svg);
    background-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
    background-color: #f2e3e3;
`;

const InfoSecond = styled.div`
    position: relative;
    height: 300px;
    top: 40px;
    width: 660px;
    margin: auto;
    @media only screen and  (max-width : 600px) { 
        display: none;
    }
`;

const InfoThird = styled.div`
    @media only screen and  (max-width : 600px) { 
        display: none;
    }
`;

const InfoSecondLine1 = styled.div`
    position: absolute;
    z-index: 9999;
    background: #ffffffb5;
    top: 8px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
    color: rgb(53, 61, 110);
    text-transform: uppercase;
    span {
        font-weight: 300;
    }
    height: 30px;
    overflow: hidden;
    animation: 1s ease 1.2s 1 normal both running infosecondline1;
    @keyframes infosecondline1 {
        from {
            width: 0;
        }
        to {
            width: 500px;
        }
    }
`;

const InfoSecondLine2 = styled.div`
    line-height: 27px;
    font-weight: 800;
    text-transform: uppercase;
    color: black;
    font-size: 20px;
    top: 160px;
    position: absolute;
    right: 0;
    width: 500px;
    text-align: right;
    animation: 1s ease 2.1s 1 normal both running infosecondline2;
    height: 30px;
    overflow: hidden;
    @keyframes infosecondline2 {
        from {
            width: 0;
        }
        to {
            width: 500px;
        }
    }
`;

const SectionTitle = styled.div`
    font-size: 26px;
    display: inline-block;
    background: #353d6e;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 300;
    color: white;
    margin: 20px 0;
`;

const SectionBody = styled.div`
    font-size: 17px;
    letter-spacing: 1px;
    color: #353d6f;
    font-weight: 500;
    text-align: justify;
`;

const Charts = styled.div`
@media only screen and  (max-width : 600px) { 
    display: none;
}
`

const ChartsArea = styled.div`
    height: 200px !important;
    position: relative;
    left: -40px;
    bottom: 18px;
    border-bottom: 1px solid #ecd8d7;
    width: 368px;
`;

const ChartBar1 = styled.div`
    background: #ecd8d8;
    width: 35px;
    height: 200px;
    position: absolute;
    left: 80px;
    bottom: 0;
    animation: 1s ease 2.2s 1 normal both running charts1};
    @keyframes charts1 {
        from {
            height: 0;
        }
        to {
            height: 200px;
        }
    }
`;

const ChartBar2 = styled.div`
    background: black;
    width: 35px;
    height: 160px;
    position: absolute;
    left: 140px;
    bottom: 0;
    animation: 1s ease 2.2s 1 normal both running charts2};
    @keyframes charts2 {
        from {
            height: 0;
        }
        to {
            height: 160px;
        }
    }
`;

const ChartBar3 = styled.div`
    background: #ecd8d8;
    width: 35px;
    height: 100px;
    position: absolute;
    left: 200px;
    bottom: 0;
    animation: 1s ease 2.2s 1 normal both running charts3};
    @keyframes charts3 {
        from {
            height: 0;
        }
        to {
            height: 100px;
        }
    }
`;

const PolandDescription = styled.div`
    right: 0;
    position: absolute;
    z-index: 9999;
    background: #ffffffb5;
    top: 8px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
    color: rgb(53, 61, 110);
    text-transform: uppercase;
    text-align: right;
    span {
        font-weight: 300;
    }
    height: 30px;
    overflow: hidden;
    animation: 1s ease 1.2s 1 normal both running polandtitle;
    @keyframes polandtitle {
        from {
            width: 0;
        }
        to {
            width: 525px;
        }
    }
`;

function ProjectInfo({moduleConfig}) {
    return (
        <>
            <SectionTitle>Cel projektu</SectionTitle> 
            <SectionBody>
                <div>
                    Opracowanie innowacyjnych związków w leczeniu potrójnie negatywnego raka piersi TNBC w oparciu o antagonizm receptora&nbsp;5&#8209;HT7. Ze względu na celowane działanie, zaprojektowane związki powinny cechować się znacznie większym bezpieczeństwem niż obecnie stosowane chemioterapeutyki. 
                </div>
                <br />
                <div>
                    Atutem projektu będzie wykorzystanie niekonwencjonalnych metod syntezy (metoda mikrofalowa oraz ultradźwiękowa), dzięki którym można znacznie obniżyć koszty samej syntezy a także zadbać o ochronę środowiska.
                </div>
            </SectionBody>
            <div {...{style:{}}}> </div>
            <MainInfo>
            <div {...{ style: { alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '20px'}}}>
                <PieChartAnim>22%</PieChartAnim>
                <MainInfoDescription>
                Rak piersi - Najczęściej diagnozowany typ nowotworu złośliwego u kobiet
                <div>około 22% wszystkich nowotworów złośliwych</div>
                </MainInfoDescription>
            </div>
            </MainInfo>
            
            
            <InfoSecond>
                <MapWorld />
                <InfoSecondLine1>
                    Ponad 2.2 miliona przypadków <span>na świecie</span>
                </InfoSecondLine1>
                <InfoSecondLine2>
                    Śmiertelność ok. 700 tysięcy
                </InfoSecondLine2>
                <div {...{style:{width: '496px', height: '119px', position: 'relative', margin: 'auto', top: '40px'}}}>
                    <div {...{style:{width: '496px', height: '28px', position: 'absolute', top: '0px'}}}>
                        <Women {...{color: '#fff2f2', position: 'absolute', width: '496px', height: '28px', keyframe: 'lol11', delay: '0s'}} />
                        <Women {...{color: '#f28882', position: 'absolute', width: '496px', height: '28px', keyframe: 'lol11', delay: '1s'}} />
                    </div>
                    <div {...{style:{width: '496px', height: '28px', position: 'absolute', top: '28px'}}}>
                        <Women {...{color: '#fff2f2', position: 'absolute', width: '496px', height: '28px', keyframe: 'lol12', delay: '0.2s'}} />
                        <Women {...{color: '#f28882', position: 'absolute', width: '496px', height: '28px', keyframe: 'lol12', delay: '1.2s'}} />
                    </div>
                    <div {...{style:{width: '496px', height: '28px', position: 'absolute', top: '56px'}}}>
                        <Women {...{color: '#fff2f2', position: 'absolute', width: '496px', height: '28px', keyframe: 'lol13', delay: '0.4s'}} />
                        <Women {...{color: '#f28882', position: 'absolute', width: '496px', height: '28px', keyframe: 'lol13', delay: '1.4s'}} />
                    </div>
                    <div {...{style:{width: '496px', height: '28px', position: 'absolute', top: '84px'}}}>
                        <Women {...{color: '#fff2f2', position: 'absolute', width: '496px', height: '28px', keyframe: 'lol14', delay: '0.6s'}} />
                        <Women {...{color: '#f28882', position: 'absolute', width: '496px', height: '28px', keyframe: 'lol14', delay: '1.6s'}} />
                        <div {...{style:{width: '304px', height: '28px', 'position': 'absolute', top: '0px', right: '0px'}}}>
                            <Women {...{color: 'black', position: 'absolute', width: '304px', height: '28px', keyframe: 'lol15', delay: '2s'}} />
                        </div>
                    </div>
                    {/* <Women {...{color: '#fff2f2', position: 'absolute', width: '496px', height: '119px', keyframe: 'lol1', delay: '0s'}} />
                    <Women {...{color: '#f28882', position: 'absolute', width: '496px', height: '119px', keyframe: 'lol2', delay: '1s'}} /> */}
                    <div {...{style:{width: '304px', height: '28px', 'position': 'absolute', top: '89px', right: 0}}}>
                        {/* <Women {...{color: 'black', position: 'absolute', width: '304px', height: '28px', keyframe: 'lol3', delay: '2s'}} /> */}
                    </div>
                </div>
            </InfoSecond>
            <InfoThird>
            <div {...{style:{width: '660px', margin: 'auto', position: 'relative'}}}>
                <MapPoland />
                <PolandDescription>2 przyczyna zgonów z powodu nowotworów <span>W Polsce</span></PolandDescription>
                <Charts>
                    <ChartsArea>
                        <ChartBar1 />
                        <ChartBar2 />
                        <ChartBar3 />
                    </ChartsArea>
                </Charts>
            </div>
            </InfoThird>
            <hr />
            <div>
                <SectionBody>
                    Zidentyfikowano, że na świecie pojawiło się tylko w&nbsp;2020
                    roku ponad 2.2 miliona przypadków, natomiast śmiertelność wynosiła blisko 700 tysięcy. W&nbsp;Polsce, rak piersi jest drugą przyczyną zgonów z powodu chorób nowotworowych u kobiet (5975
                    zgonów w&nbsp;2014&nbsp;r.; 14% ogółu zgonów; standaryzowany współczynnik umieralności 14,8/100 000).
                    TNBC to złośliwy, heterogenny podtyp raka, w którym nie obserwuje się obecności receptorów: ER,
                    PR i&nbsp;HER2. Odsetkowo stanowi on ok 15-20% wszystkich przypadków raka piersi, najczęściej
                    zdiagnozowanych u młodych kobiet.
                </SectionBody>

                <SectionBody>
                Dotychczasowe leczenie pacjentek, u&nbsp;których zdiagnozowany został potrójnie negatywny rak
piersi, opiera się na chemioterapii a&nbsp;następnie resekcji tkanki zajętej nowotworowo (w&nbsp;praktyce
całkowita lub częściowa mastektomia), lub odwrotnie. W przypadku chemioterapii, stosowanie
leków hormonalnych, podobnie jak inhibitorów HER2, nie przynosi efektu ze względu na brak
receptorów ER, PRG i HER2. Korzyści uzyskuje się jedynie dzięki chemioterapii klasycznej z&nbsp;użyciem cytostatyków. Obecnie stosowana chemioterapia o&nbsp;działaniu ogólnoustrojowym mimo,
że potrafi być skuteczna to niesie ze sobą poważne skutki uboczne.
    </SectionBody>

                

    <SectionTitle> Opis projektu </SectionTitle>
    <SectionBody> Na przeciągu ostatniego dziesięciolecia potwierdzono, że&nbsp;serotonina (5-HT) i&nbsp;ścieżki sygnalizacyjne
zależne od 5-HT, wpływa/-ją na proces inicjacji, kancerogenezy oraz przerzutowania (rak płuc, rak
jelita grubego, rak piersi, rak wątroby) [19,20]. Sugeruje to, że serotonina może stać się obiektem
zainteresowania przy opracowaniu nowych terapii przeciwnowotworowych. Ciekawy jest fakt, że 5-
HT ma działanie hamujące na wzrost prawidłowego nabłonka gruczołu sutkowego, stymuluje
natomiast proliferację komórek raka piersi w&nbsp;stężeniach nawet submikromolarnych [22]. Ponadto
wiadomo, że mediatorem w szlaku sygnałowym związanym z&nbsp;angiogenezą na skutek oddziaływania 5-
HT, jest mechanizm zbliżony do tego odpowiadającego za angiogenezę na skutek oddziaływania
czynnika wzrostu śródbłonka naczyniowego (VEGF) — np.&nbsp;aktywacja szlaku sygnałowego PI3K/Akt
[23] — co sugeruje, że cząsteczki sygnałowe odpowiedzialne za proliferację/naciekanie nowotworu i
angiogenezę mogą w pewnym stopniu się pokrywać [21]. Gautam et al.&nbsp;[21] wykazali, że&nbsp;zablokowanie receptora 5-HT<sub>7</sub> za pomocą SB269970 (antagonista) spowodował zauważalny efekt
antyproliferacyjny linii MDA-MB231, co świadczy o&nbsp;tym, że&nbsp;udział tego receptora może mieć istotny
wpływ na właściwości przeciwnowotworowe. Zbadano, że&nbsp;zablokowanie kinaz Src, PI3K/Akt i Gβγ był
odpowiedzialny za ten efekt. Ci sami autorzy prowadzili również badania nad inwazyjnym
potencjałem komórek linii MDA-MB-231. Okazało się ponownie, że&nbsp;zablokowanie receptora 5-HT<sub>7</sub>
spowodowało obniżenie potencjału inwazyjnego komórek nowotworowych linii MDA-MB231. W&nbsp;przypadku potencjału antyinwazyjnego istotny wpływ mają inhibitory aktywujące podjednostkę Gαs
receptora 5-HT<sub>7</sub>, w&nbsp;tym inhibitory cyklazy adenylowej, a&nbsp;także kinazy Src/ERK. Mając na uwadze
powyższe, można wysunąć hipotezę, na podstawie której, pewne związki będące antagonistami receptora 5-HT<sub>7</sub>, mogą mieć działanie przeciwnowotworowe. 
<br/>
<br/>
Korzystając z tego podejścia byliśmy w&nbsp;stanie zaprojektować związki, które we wstępnych badaniach wydają się potwierdzać wysuniętą
hipotezę.
<br/>
<br/>
Z racji tego, że projekt jest z&nbsp;zakresu chemii medycznej, można go podzielić na dwie główne składowe
– część chemiczną oraz część biologiczną. 
<br/>
<ul>
    <li style={{listStyle: "upper-alpha", margin: '10px'}}>
    <b>Celem części chemicznej</b> jest synteza zaprojektowanych związków o potencjalnym działaniu biologicznym. Zostaną dobrane odpowiednie ścieżki syntetyczne, a także zoptymalizowana zostanie sama metoda syntezy, w celu uzyskania jak największej wydajności związków końcowych.
    </li>
    <li style={{listStyle: "upper-alpha", margin: '10px'}}>
    <b>Celem części biologicznej</b> projektu jest ocena aktywności biologicznej zsyntezowanych cząsteczek. Dla obiecujących związków zostaną przeprowadzone dokładniejsze testy mające na celu wyjaśnienie mechanizmu ich działania (również na podstawie modelowania molekularnego), a także wykonane zostaną testy bezpieczeństwa w kontekście badań in vivo. W końcowej fazie projektu zostaną przeprowadzone badania in vivo dla najbardziej obiecujących cząsteczek, w celu określenia ich skuteczności terapeutycznej w modelu zwierzęcym wobec ludzkich komórek raka TNBC.
    </li>
</ul>
<br/>
Ostatnim etapem projektu będzie próba komercjalizacji efektów końcowych projektu.</SectionBody>
            </div>
        </>
    );
  };
  
export default ProjectInfo;
  