import React from 'react';
import { Avatar } from '../atoms/PageCover/ProjectLead';

import styled from 'styled-components';

const Name = styled.div`
    font-size: 28px;
    font-weight: 300;
    color: #363d6e;
`

const Description = styled.div`
    font-size:16px;
    padding: 20px;
    text-align: justify;
    color: #353d6f;
    letter-spacing: 1px;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 20px;
`

const Header = styled.div`
    font-size: 26px;
    display: inline-block;
    background: rgb(53, 61, 110);
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 300;
    color: white;
    margin: 20px 0px;
`

const ALine = styled.div`
    font-size: 18px;
    line-height: 1.5;
    margin: 5px 0;
    color: #363d6e;
    letter-spacing: 1px;
    a {
        color: #363d6e;
    }
`

function Contact({moduleConfig}) {
    return (
        <>
            <Header>Dane adresowe</Header>
            <div>
                <ALine {...{style: {fontWeight: 700}}}>dr inż. Damian Kułaga<div {...{style: {fontWeight: 300}}}>kierownik projektu</div></ALine>
                <ALine>Wydział Inżynierii i Technologii Chemicznej</ALine>
                <ALine>Katedra Chemii i Technologii Organicznej</ALine>
                <ALine {...{style: {marginTop: '10px'}}}>nr tel.: 516790180</ALine>
                <ALine>e-mail: <a href="mailto:damian.kulaga@pk.edu.pl">damian.kulaga@pk.edu.pl</a></ALine>
                <ALine><a href="https://pl.linkedin.com/in/damian-ku%C5%82aga-04766276" target="_blank" rel="noreferrer">LinkedIn</a></ALine>
                <ALine><a href="https://www.researchgate.net/profile/Damian-Kulaga" target="_blank" rel="noreferrer">ResearchGate</a></ALine>
            </div>
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe {...{
                        title: "PK",
                        width:"100%",
                        height:"400",
                        id:"gmap_canvas",
                        src:"https://maps.google.com/maps?q=Department%20of%20Chemical%20Engineering%20and%20Technology&t=&z=13&ie=UTF8&iwloc=&output=embed",
                        frameborder:"0",
                        scrolling:"no",
                        marginheight:"0",
                        marginwidth:"0"
                    }}></iframe>
                </div>
            </div>
        </>
    );
  };
  
export default Contact;
  