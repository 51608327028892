import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import { Container } from './styles';
import styled from 'styled-components';

import MenuIcon from '@material-ui/icons/Menu';

const MobileMenuElement = styled.a`
  padding: 20px;
  list-style: none;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  color: black;
  border-bottom: 1px solid #dddddd;
}
`;

export const FooterLogosWrapper = styled.div`
`;

export const FooterMenu = styled.div`
  display: inline-flex;
  list-style: none;
  justify-content: end;

  margin: 0;
  padding: 0;
  align-items: center;
  flex: 1;
  margin-right: 20px;
  a {
    margin: 10px;
    color: white;
    font-size: 14px;
    color: white;
    text-decoration: none;
  }
`;

function Footer({menu}) {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleDrawer = () => (event) => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div {...{className: 'footer'}}>
      <Hidden smDown>
        <FooterMenu>
          {menu.pl.map(el => <a {...{href: el.path}}>{el.name}</a>)}
        </FooterMenu>
      </Hidden>
    </div>
  )
};

export default Footer;


// rgb(53, 92, 182)