import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import SSRProvider from 'react-bootstrap/SSRProvider';

ReactDOM.hydrate(
  <React.StrictMode>
    <SSRProvider>
      <App {...{appData: window['__INITIAL__DATA__']}}/>
    </SSRProvider>
  </React.StrictMode>,
  document.getElementById('root')
);