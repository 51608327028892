import React from 'react';
import { Avatar } from '../atoms/PageCover/ProjectLead';

import styled from 'styled-components';

const Name = styled.div`
    font-size: 28px;
    font-weight: 300;
    color: #363d6e;
`

const Description = styled.div`
    font-size:16px;
    padding: 20px;
    text-align: justify;
    color: #353d6f;
    letter-spacing: 1px;
    h2 {
        font-size: 20px;
        font-weight: bold;
    }
`

const Wrapper = styled.div`
    img {
        max-width: 120px;
    }
    a {
        display: flex;
        align-items: center;
        padding: 5px 20px;
        text-decoration: none;
        text-align: left;

        @media only screen and  (max-width : 800px) { 
            flex-direction: column;
            align-items: flex-start;
        }
    }
`

const Header = styled.div`
    font-size: 26px;
    display: inline-block;
    background: rgb(53, 61, 110);
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 300;
    color: white;
    margin: 20px 0px;
`

function Media({moduleConfig}) {
    return (
        <>
            <Header>Media</Header>
            <Wrapper>
                <a href="https://www.radiokrakow.pl/audycje/naukowcy-politechniki-krakowskiej-zaprojektowali-zwiazek-skuteczny-w-leczeniu-zlosliwego-raka-piersi">
                    <div>
                        <img alt="Radio Kraków" src="https://tnbc.pk.edu.pl/images/logo_rk.png" />
                    </div>
                    <Description>
                        <h2>Naukowcy Politechniki Krakowskiej zaprojektowali związek skuteczny w&nbsp;leczeniu złośliwego raka piersi</h2>
                        Skutecznie ogranicza rozwój chorobotwórczych komórek i jest bezpieczniejszy od powszechnie stosowanych chemioterapeutyków - substancja opracowana przez naukowców Politechniki Krakowskiej ma skutecznie leczyć potrójnie negatywnego raka piersi, tzw.&nbsp;typu TNBC czyli najbardziej złośliwego nowotworu diagnozowanego u&nbsp;kobiet. Związek ma być składnikiem leku celowanego, który uderza w&nbsp;konkretne receptory komórek nowotworowych, blokując ich namnażanie.
                    </Description>
                </a>
            </Wrapper>
            <hr />
            <Wrapper>
                <a href="https://www.forbes.pl/innowacje-w-leczeniu-raka-piersi/j24m84m">
                    <div>
                        <img alt="Forbes" src="https://tnbc.pk.edu.pl/images/logo_fb.png" />
                    </div>
                    <Description>
                        <h2>Innowacje w leczeniu raka piersi</h2>
                        Nowotwory, a&nbsp;w&nbsp;szczególności nowotwory złośliwe postrzegane są już jako choroba cywilizacyjna. Pomimo zwiększonej świadomości ludzi, dostępu do badań przesiewowych a&nbsp;także postępowi w&nbsp;dziedzinie onkologii oraz nauk podstawowych w&nbsp;tym biologii molekularnej czy też farmacji, całkowity odsetek pacjentów ze&nbsp;zdiagnozowaną chorobą ciągle się powiększa.
                    </Description>
                </a>
            </Wrapper>
            <hr />
            <Wrapper>
                <a href="https://www.pk.edu.pl/index.php?option=com_content&view=article&id=4680:naukowcy-politechniki-krakowskiej-na-tropie-leku-na-zlosliwego-raka-piersi&catid=49&lang=pl&Itemid=1152">
                    <div>
                        <img alt="Politechnika Krakowska" src="https://www.pk.edu.pl//templates/pk18-tpl/images/PK/PK.png" />
                    </div>
                    <Description>
                        <h2>Naukowcy Politechniki Krakowskiej na tropie leku na złośliwego raka piersi</h2>
                        Naukowcy Politechniki Krakowskiej pracują nad nowymi związkami chemicznymi, które mogą pomóc w walce z potrójnie negatywnym rakiem piersi. To jeden z najbardziej złośliwych kobiecych nowotworów (nierzadko dotykający też mężczyzn). 
                    </Description>
                </a>
            </Wrapper>
            <hr />
            <Wrapper>
                <a href="https://forumakademickie.pl/badania/na-tropie-leku-na-zlosliwego-raka-piersi/">
                    <div>
                        <img alt="Forum Akademickie" src="https://forumakademickie.pl/wp-content/uploads/2020/08/logo-1024x285.png" />
                    </div>
                    <Description>
                        <h2>Na tropie leku na złośliwego raka piersi</h2>
                        Nad nowymi związkami chemicznymi, które mogą pomóc w walce z potrójnie negatywnym rakiem piersi, pracują naukowcy Politechniki Krakowskiej i instytutów PAN. Celowany lek, który powstanie na bazie uzyskanej przez nich substancji, będzie skuteczniejszy i bezpieczniejszy dla pacjenta niż obecnie stosowane chemioterapeutyki.
                    </Description>
                </a>
            </Wrapper>
            <hr />
            <Wrapper>
                <a href="https://naukawpolsce.pl/aktualnosci/news%2C95532%2Cnaukowcy-z-politechniki-krakowskiej-szukaja-leku-na-raka-piersi.html">
                    <div>
                        <img alt="Forum Akademickie" src="https://tnbc.pk.edu.pl/images/naukawpolsce_logo.png" />
                    </div>
                    <Description>
                        <h2>Naukowcy z Politechniki Krakowskiej szukają leku na raka piersi</h2>
                        Naukowcy z Politechniki Krakowskiej pracują nad substancją, która mogłaby wejść w skład leków wykorzystywanych w chemioterapii potrójnie negatywnego raka piersi – jednego z najbardziej złośliwych nowotworów kobiecych, nierzadko dotykających także mężczyzn.
                    </Description>
                </a>
            </Wrapper>
            <hr />
            <Wrapper>
                <a href="https://www.rynekzdrowia.pl/Serwis-Onkologia/Krakowscy-naukowcy-poszukuja-leku-pomocnego-przy-chemioterapii-raka-piersi,242975,1013.html">
                    <div>
                        <img alt="Forum Akademickie" src="https://www.rynekzdrowia.pl/images/v2021/rz_ukraina.svg" />
                    </div>
                    <Description>
                        <h2>Naukowcy z Politechniki Krakowskiej szukają leku na raka piersi</h2>
                        Naukowcy z Politechniki Krakowskiej pracują nad substancją, która mogłaby wejść w skład leków wykorzystywanych w chemioterapii potrójnie negatywnego raka piersi – jednego z najbardziej złośliwych nowotworów kobiecych, nierzadko dotykających także mężczyzn.
                    </Description>
                </a>
            </Wrapper>
            <hr />
            <Wrapper>
                <a href="https://lovekrakow.pl/aktualnosci/naukowcy-z-politechniki-krakowskiej-pracuja-nad-lekiem-na-zlosliwego-raka-piersi_50013.html">
                    <div>
                        <img alt="Forum Akademickie" src="https://lovekrakow.pl/img/v8/lk-logo-2021-kolor-dark-SVG.svg" />
                    </div>
                    <Description>
                        <h2>Naukowcy z Politechniki Krakowskiej pracują nad lekiem na złośliwego raka piersi</h2>
                        Naukowcy Politechniki Krakowskiej pracują nad nowymi związkami chemicznymi, które mogą pomóc w walce z potrójnie negatywnym rakiem piersi.
                    </Description>
                </a>
            </Wrapper>
            <hr />
            <Wrapper>
                <a href="https://krakow.tvp.pl/66903349/naukowcy-z-politechniki-krakowskiej-szukaja-leku-na-raka-piersi">
                    <div>
                        <img alt="Forum Akademickie" src="https://tnbc.pk.edu.pl/images/tvp3krakow_logo.png" />
                    </div>
                    <Description>
                        <h2>Naukowcy z Politechniki Krakowskiej szukają leku na raka piersi</h2>
                        Naukowcy z Politechniki Krakowskiej pracują nad substancją, która mogłaby wejść w skład leków wykorzystywanych w chemioterapii potrójnie negatywnego raka piersi – jednego z najbardziej złośliwych nowotworów kobiecych, nierzadko dotykających także mężczyzn.
                    </Description>
                </a>
            </Wrapper>
            <hr />
            <Wrapper>
                <a href="https://www.radiokrakow.pl/aktualnosci/krakow/naukowcy-z-politechniki-krakowskiej-szukaja-leku-na-raka-piersi">
                    <div>
                        <img alt="Radio Kraków" src="https://tnbc.pk.edu.pl/images/logo_rk.png" />
                    </div>
                    <Description>
                        <h2>Naukowcy z Politechniki Krakowskiej szukają leku na raka piersi</h2>
                        Naukowcy z Politechniki Krakowskiej pracują nad substancją, która mogłaby wejść w skład leków wykorzystywanych w chemioterapii potrójnie negatywnego raka piersi – jednego z najbardziej złośliwych nowotworów kobiecych, nierzadko dotykających także mężczyzn.
                    </Description>
                </a>
            </Wrapper>
        </>
    );
  };
  
export default Media;
  