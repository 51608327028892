import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import styled from 'styled-components';

import { NewsIntroWrapper, NewsIntroDate, NewsIntroShort, NewsIntroTitle } from './styles';

const SlideElement = styled.a<{imgUrl: string}>`
  display: block;
  background: url(${({imgUrl}) => imgUrl});
  width: 100%;
  height: calc(100vh - 105px);
  xheight: 80vh;
  background-size: cover;
  background-position: 50% 50%;
`;

const SliderCaptionTitle = styled.div`
  background: #065e7ffe;
  display: inline-block;
  padding: 5px 10px;
  font-size: 26px;
  margin: 5px 0;
`;

const SliderCaptionDesc = styled.div`
  background: #065e7ffe;
  display: inline-block;
  padding: 5px 10px;
  font-size: 18px;
`;

const SliderCaptionDescLink = styled.a`
  color: white;
  &:hover {
    color: white;
  }
`;

function Slider() {

    return (
        <>
        <Carousel>
        <Carousel.Item>
            <SlideElement {...{
              imgUrl: '/slide1.jpg',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: '/slide2.png',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://www.gov.pl/photo/format/9a3fa2c0-2890-491a-b06d-94c1fccf3770/resolution/1460x616',
              href: 'https://www.gov.pl/web/ncbr/liderzy-innowacji-nagrodzeni-przez-ncbr',
              '_target': 'blank'
            }}/>
            <Carousel.Caption {...{style: {
              bottom: '150px',
              textAlign: 'left'
            }}}>
              <SliderCaptionDescLink {...{
                href: 'https://www.gov.pl/web/ncbr/liderzy-innowacji-nagrodzeni-przez-ncbr',
                '_target': 'blank'
              }}>
                <div>
                  <SliderCaptionTitle>Liderzy innowacji nagrodzeni przez NCBR</SliderCaptionTitle>
                </div>
                <div>
                  <SliderCaptionDesc>Podsumowanie XII edycji projektu Lider</SliderCaptionDesc>
                </div>
              </SliderCaptionDescLink>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: '/slide4.png',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://tnbc.pk.edu.pl/slide5.jpeg',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://tnbc.pk.edu.pl/slide6.jpeg',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://tnbc.pk.edu.pl/slide7.jpeg',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://tnbc.pk.edu.pl/slide8.jpeg',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://tnbc.pk.edu.pl/slide9.jpeg',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://tnbc.pk.edu.pl/slide10.jpeg',
              href: '#',
            }}/>
          </Carousel.Item>
          <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://tnbc.pk.edu.pl/slide11.jpeg',
              href: '#',
            }}/>
          </Carousel.Item>
          {/* <Carousel.Item>
            <SlideElement {...{
              imgUrl: '/purification.png',
              href: '#timeline',
            }}/>
            <Carousel.Caption {...{style: {
              bottom: '150px',
              textAlign: 'left'
            }}}>
              <SliderCaptionDescLink {...{
                href: '#timeline',
              }}>
                <div>
                  <SliderCaptionTitle>Pipeline projektu</SliderCaptionTitle>
                </div>
              </SliderCaptionDescLink>
            </Carousel.Caption>
          </Carousel.Item> */}
          {/* <Carousel.Item>
            <SlideElement {...{
              imgUrl: 'https://picsum.photos/1024/490'
            }}/>
            <Carousel.Caption {...{style: {
              bottom: '150px',
              textAlign: 'left'
            }}}>
              <div>
                <SliderCaptionTitle>Third slide label</SliderCaptionTitle>
              </div>
              <div>
                <SliderCaptionDesc>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</SliderCaptionDesc>
              </div>
            </Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
        </>
    );
  };
  
export default Slider;
  