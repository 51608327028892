import React from 'react';
import { Avatar } from '../atoms/PageCover/ProjectLead';

import styled from 'styled-components';

const Name = styled.div`
    font-size: 28px;
    font-weight: 300;
    color: #363d6e;
`

const Description = styled.div`
    font-size:16px;
    padding: 20px;
    text-align: justify;
    color: #353d6f;
    letter-spacing: 1px;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 20px;
`

const Header = styled.div`
    font-size: 26px;
    display: inline-block;
    background: rgb(53, 61, 110);
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 300;
    color: white;
    margin: 20px 0px;
`

function Team({moduleConfig}) {
    return (
        <>
            <Header>Zespół</Header>
            <Wrapper>
            <Avatar {...{image: '/kulaga.png'}}/>
                <Name>dr inż. Damian Kułaga</Name>
            </Wrapper>
            <Description>
                Kierownik projektu, asystent badawczo-dydaktyczny w Katedrze Chemii i&nbsp;Technologii Organicznej
                Politechniki Krakowskiej. Pracuje w zespole badawczym kierowanym przez dr Jolantę Jaśkowską. W&nbsp;ramach pracy naukowej prowadzi badania z zakresu chemii medycznej w obrębie nowych terapii
                OUN (Ośrodkowy Układ Nerwowy) oraz onkologii. Posiada szerokie doświadczenie w zakresie syntezy
                organicznej oraz analityki zdobyte zarówno podczas studiów jak i&nbsp;pracy w&nbsp;krakowskiej firmie
                <br/>
                biotechnologicznej – Selvita S.A. Doskonalił umiejętności z&nbsp;zakresu modelowania molekularnego
                podczas trzymiesięcznego stażu w&nbsp;Instytucie Farmakologii Państwowej Akademii Nauk w&nbsp;Krakowie.
                Był członkiem zespołu badawczego w&nbsp;trzech projektach naukowych realizowanych na&nbsp;Politechnice
                Krakowskiej pod kierownictwem dr&nbsp;Jolanty Jaśkowskiej oraz kierownikiem dwóch projektów
                naukowych (DS.-M) a także dwóch grantów obliczeniowych w&nbsp;infrastrukturze PLGrid. W&nbsp;projekcie
                LIDER XII odpowiedzialny jest za kierowanie pracami zespołu, a&nbsp;także projektowanie nowych
                związków na podstawie modelowania molekularnego oraz wyników wcześniejszych badań in&nbsp;vitro.
                Odpowiedzialny jest również za prace syntetyczne.
            </Description>
            {/* <hr />
            <Wrapper>
                <Avatar {...{image: '/satala.png'}}/>
                <Name>dr Grzegorz Satała</Name>
            </Wrapper>
            <Description>
            Absolwent Biofizyki na Wydziale Biochemii, Biofizyki i&nbsp;Biotechnologii Uniwersytetu Jagiellońskiego w&nbsp;Krakowie. Grzegorz jest zatrudniony na stanowisku asystenta w Zakładzie Chemii Leków w Instytucie
            Farmakologii Polskiej Akademii Nauk, gdzie zdobył doświadczenie w poszukiwaniu nowych leków w
            oparciu o wysokoprzepustowe przeszukiwanie bibliotek związków za pomocą metod in silico i&nbsp;in&nbsp;vitro
            dla eksploracji zależności struktura-aktywność. Jego badania koncentrują się na projektowaniu i&nbsp;poszukiwania nowych leków przeciwdepresyjnych i środków przeciwlękowych. Ponadto brał udział w
            licznych projektach badawczych i stażach w branży farmaceutycznej w Polsce i innych krajach.
            Zdobywał doświadczenie naukowe, między innymi, w&nbsp;Pliva Kraków w Wydziale Zapewnienia Jakości
            oraz w Wydziale Analityki, Pionu Badań i Rozwoju. W&nbsp;projekcie LIDER XII odpowiedzialny jest za
            wykonywanie badań powinowactwa do wybranych receptorów serotoninowych oraz określenie
            funkcji wewnętrznej.
            </Description> */}
            <hr />
            <Wrapper>
                <Avatar {...{image: '/stachowicz-suhs.png'}}/>
                <Name>mgr Martyna Stachowicz-Suhs</Name>
            </Wrapper>
            <Description>
            W 2017 roku uzyskała tytuł magistra na Uniwersytecie Wrocławskim na kierunku biotechnologia ze
specjalnością biotechnologia medyczna. Pracę w Instytucie Immunologi i Terapii Doświadczalnej im.
Ludwika Hirszfelda Polskiej Akademii Nauk we Wrocławiu rozpoczęła już w trakcie studiów
pierwszego stopnia i kontynuuje ją do dziś. Jest uczestniczką Interdyscyplinarnych Środowiskowych
Studiów Doktoranckich w ramach projektu BioTechNan, a swoją pracę doktorską realizuje w zakresie
dyscplin biologia oraz medycyna. Główne zainteresowania naukowe to rola komórek układu
odpornościowego w mikrośrodowisku nowotworów oraz wpływ witaminy D na tworzenie ognisk
przerzutowych i udział makrofagów w inicjacji tego procesu w kontekście progresji raka gruczołu
sutkowego. Dotychczas brała udział w 5 projektach finansowanych m.in. przez Narodowe Centrum
Nauki, Narodowe Centrum Badań i Rozwoju. Ponadto, uczestniczyła w wielu krajowych stażach
naukowych oraz zagranicznym w The State University of New York; Cancer Research Center at Albany
w Stanach Zjednoczonych. Jest współautorką 6 publikacji oraz 2 zgłoszeń patentowych. W projekcie
LIDER XII odpowiedzialna jest za wykonywanie badań cytotoksyczności na wybranych liniach
komórkowych a także za określenie mechanizmu działania związków. Współodpowiedzialna jest
również za wykonywanie badań in vivo.
            </Description>
            <hr />
            <Wrapper>
                <Avatar {...{image: '/zareba.png'}}/>
                <Name>mgr inż. Przemysław Zaręba</Name>
            </Wrapper>
            <Description>
            Asystent badawczo-dydaktyczny w Katedrze Technologii Chemicznej i&nbsp;Analityki Środowiskowej
WIiTCh PK. Jego zainteresowania naukowe skupiają się na poszukiwaniu nowych związków
bioaktywnych, ze szczególnym uwzględnieniem farmakoterapii chorób ośrodkowego układu
nerwowego, w&nbsp;tym depresji, chorób neurodegradacyjnych oraz nowotworów OUN. Posiada bogate
doświadczenie w zakresie analityki oraz syntezy organicznej, szczególnie z&nbsp;zastosowaniem
ekologicznych metod syntezy w polu promieniowania mikrofalowego, metod sonochemicznych oraz
mechanochemicznych. Ponadto w&nbsp;prowadzonych badaniach stosuje szeroki wachlarz metod
modelowania molekularnego, w&nbsp;przewidywaniu aktywności biologicznej oraz analizie właściwości
związanych z&nbsp;przebiegiem reakcji organicznych. Uczestniczył jako wykonawca w&nbsp;sześciu projektach
badawczych o&nbsp;tematyce oscylującej wokół chemii i technologii leków. Kilkukrotnie prowadził granty
badawcze oraz obliczeniowe w ramach działalności statutowej młodych naukowców oraz struktury
PL-Grid. Obecnie kieruje projektem badawczym „Nowe ligandy receptora 5-HT 6 z&nbsp;grupy
sulfonamidowych pochodnych cyklicznych aryloguanidyn w&nbsp;leczeniu glejaków wielopostaciowych”
finansowanym przez Narodowe Centrum Nauki, dotyczącym zastosowania ligandów receptorów
serotoninowych w leczeniu glejaków wielopostaciowych. Wyniki swoich badań prezentował na
ponad 20 konferencjach naukowych i&nbsp;targach wynalazków, a także w publikacjach naukowych. Część
opracowanych przez niego wyników została objęta ochroną patentową na terenie Polski oraz
nagrodzona na krajowych i&nbsp;międzynarodowych targach wynalazków.
            </Description>
        </>
    );
  };
  
export default Team;
  