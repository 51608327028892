import React, { FC } from 'react';
// import Home from './Home';
import Header from './Header';
import Footer from './Footer';
// import { PageCoverWrapper, PageCoverBackground, PageCoverTitle } from './atoms/PageCover/PageCover';
import { Grid } from '@material-ui/core';
import ProjectTimeline from "./page-modules/Timeline";
// import { ProjectLead, ProjectLeadHeader, ProjectLeadName, Avatar, ProjectLeadBodyWrapper } from './atoms/PageCover/ProjectLead';
// import NewsIntro from './page-modules/NewsIntro';
import ProjectInfo from './page-modules/ProjectInfo';
import Slider from './page-modules/Slider';
import Contact from './page-modules/Contact';
import Team from './page-modules/Team';
import Media from './page-modules/Media';
import styled from 'styled-components';

// console.log('m.foom.foom.foom.foom.foom.foom.foom.foo', m.foo)

type AppData = {
  appData: {
    pageTitle: string,
    coverImage: string,
    pageModules: {
      modules: any[],
      data: any,
      indexes: any
    },
    menu: {
      path: string,
      name: string
    }
  }
};

const App: FC<AppData> = ({appData}) => {
  // const moduleMap = (module) => {
  //   // console.log('>>>module', module.id)
  //   // console.log('appData.pageModules.moduleDataIndexes>>', appData.pageModules.indexes)
  //   switch (module.type) {
  //     case 'NewsIntro':
  //       {
  //         const moduleData = appData.pageModules.data[appData.pageModules.indexes[module.id]];
  //         console.log(">>moduleData>", moduleData)
  //         return (<></>)
  //         // return (<Slider {...{moduleData, moduleConfig: module.config}} />)
  //         // return (<NewsIntro {...{moduleData, moduleConfig: module.config}} />)
  //       }
  //     case 'ProjectInfo':
  //       return (<ProjectInfo {...{moduleConfig: module.config}} />)
  //     case 'Team':
  //         return (<Team {...{moduleConfig: module.config}} />)
  //     case 'Contact':
  //       return (<Contact {...{moduleConfig: module.config}} />)
  //     case 'ProjectTimeline':
  //       return (<ProjectTimeline />)
  //     default:
  //       return (<></>);
  //   }
  // }

  const ProjectName = styled.div`
    background: #085d7f;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 300;
    text-align: center;
  `;

  const Separator = styled.div`
    margin-bottom: 50px;
    padding-top: 50px;
    border-bottom: 1px solid #99c595;
  `;

  return (
    <>
      <Header {...{menu: appData.menu}}/>
      <ProjectName>Innowacyjne związki first-in-class, jako ligandy receptora 5-HT<sub>7</sub> w leczeniu potrójnie negatywnego raka piersi TNBC</ProjectName>
      <div>
        <Slider />
      </div>
      <div className='container main-container'>
        <Grid
          container
          id="about"
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={10} className="px-md-3">
            <ProjectInfo {...{moduleConfig: null}} />
            <Separator />
            <div id="timeline">
              <ProjectTimeline />
            </div>
            <Separator />
            <div id="media">
              <Media {...{moduleConfig: null}} />
            </div>
            <Separator />
            <div id="team">
              <Team {...{moduleConfig: null}} />
            </div>
            <Separator />
            <div id="contact">
              <Contact {...{moduleConfig: null}} />
            </div>
          </Grid>
          {/* <Grid item xs={12} md={10} className="px-md-3">
            {appData.pageModules.modules.map(module => moduleMap(module))}
          </Grid> */}
          {/* <Grid item xs={12} md={4} className="px-md-3">
            <ProjectLead>
              <ProjectLeadHeader>Kierownik projektu</ProjectLeadHeader>
              <ProjectLeadBodyWrapper>
                <Avatar {...{image: 'https://chemia.pk.edu.pl/wp-content/uploads/2021/11/IMG_9731_forum-1-768x912.jpg'}}/>
                <ProjectLeadName {...{href: 'https://chemia.pk.edu.pl/pracownik/damian-kulaga/'}}>mgr inż.	Damian Kułaga</ProjectLeadName>
              </ProjectLeadBodyWrapper>
            </ProjectLead>
          </Grid> */}
        </Grid>
      </div>
      <Footer {...{menu: appData.menu}}/>
    </>
  );   
}

export default App