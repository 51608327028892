import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import { Container } from './styles';
import styled from 'styled-components';

import MenuIcon from '@material-ui/icons/Menu';

const MobileMenuElement = styled.a`
  padding: 20px;
  list-style: none;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  color: black;
  border-bottom: 1px solid #dddddd;
}
`;

export const HeaderLogosWrapper = styled.div`
`;

export const HeaderMenu = styled.div`
  display: inline-flex;
  list-style: none;
  justify-content: end;

  margin: 0;
  padding: 0;
  align-items: center;
  flex: 1;
  margin-right: 20px;
  a {
    margin: 10px;
    color: white;
    font-size: 14px;
    color: white;
    text-decoration: none;
  }
`;

function Header({menu}) {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleDrawer = () => (event) => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div {...{className: 'header'}}>
      <a href='/'>
        <img {...{
          className: 'logo logo-lider',
          alt:"logo", 
          src: '/lider_logo.png'
        }} />
      </a>
      <Hidden smDown>
        <HeaderLogosWrapper>
        <a href="https://www.gov.pl/web/ncbr/lider">
          <img {...{
            className: 'logo logo-w logo-ncbir',
            alt:"logo", 
            src:"/ncbr_logo_pl.png"
        }} />
        </a>
        <a href="https://chemia.pk.edu.pl/">
          <img {...{
            className: 'logo logo-wchpk',
            alt:"logo", 
            src:"/chpk_logo.png"
          }} />
        </a>
        <a href="https://pk.edu.pl">
          <img {...{
            className: 'logo logo-pk',
            alt:"logo", 
            src:"/pk.png"
          }} />
        </a>
        </HeaderLogosWrapper>
        <HeaderMenu>
          {menu.pl.map(el => <a {...{href: el.path}}>{el.name}</a>)}
        </HeaderMenu>
      </Hidden>
      <Hidden mdUp>
      <Button {...{style:{
        flex: 1,
        justifyContent: 'end',
        padding: '20px'
      }}} onClick={toggleDrawer()}><MenuIcon style={{color: 'white', fontSize: '16px'}}/></Button>
    <Drawer anchor='right' open={menuOpen} onClose={toggleDrawer()}>
      <Container>
        {menu.pl.map(el => <MobileMenuElement {...{href: el.path}}>{el.name}</MobileMenuElement>)}
      </Container>
    </Drawer>
    </Hidden>
    </div>
  )
};

export default Header;


// rgb(53, 92, 182)