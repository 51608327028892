import styled from 'styled-components';

export const ProjectLead = styled.div`
  display: block;
  position: relative;
  border-bottom: 1px solid #e6eef0;
  margin: 10px;
  padding: 15px 0;
`;

export const ProjectLeadHeader = styled.h4`
  margin: 10px 0;
  font-weight: 400;
  padding: 6px;
  background: rgb(53 93 181);
  color: white;
  display: inline-block;
  font-size: 17px;
`;

export const ProjectLeadBodyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ProjectLeadName = styled.a`
  margin: 0;
  font-weight: 300;
  color: #363d6e;
  display: inline-block;
  font-size: 24px;
  text-decoration: none;
  color: #355db5;
  flex: 1;
  margin-left: 10px;
`;

export const Avatar = styled.div<{image: string}>`
  margin: 0;
  font-weight: 300;
  color: black;
  display: inline-block;
  border-radius: 100px;
  background-image: ${({image}) => `url(${image})`};
  height: 80px;
  width: 80px;
  min-width: 80px;
  min-height: 80px;
  background-size: cover;
  box-shadow: 0px 0px 1px #2a7586;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center;
`;
